<template>
  <t-layout :style="{ width: '1480px', margin:'0 auto' }">
    <t-header>
      <t-head-menu theme="light" value="item1" height="120px">
        <template #logo>
          <a href="/"><img height="45" class="logo" src="../assets/img/logo.png" alt="logo" /></a>
        </template>
        <t-menu-item value="item1"> <a href="/">首页</a> </t-menu-item>
        <t-menu-item value="item2"> <a href="#company">公司介绍</a> </t-menu-item>
        <t-menu-item value="item2"> <a href="#game">游戏产品</a> </t-menu-item>
        <t-menu-item value="item2"> <a href="#contact">联系我们</a> </t-menu-item>
      </t-head-menu>
    </t-header>
    <t-content :style="{ background: 'white' }">
      <div>
        <t-swiper
          :duration="300"
          :interval="2000"
        >
          <t-swiper-item>
            <img src="../assets/img/swiper/swiper3.png" :style="{ width: '1480px' }" alt="萌猪出击">
          </t-swiper-item>
          <t-swiper-item>
            <img src="../assets/img/swiper/swiper4.png" :style="{ width: '1480px' }" alt="萌猪出击">
          </t-swiper-item>
        </t-swiper>
      </div>
      <t-divider id="company"><t-tag theme="primary" variant="light-outline">公司介绍</t-tag></t-divider>
      <t-row style="margin: 10px auto;" :gutter="12">
        <t-col span="4">
          <t-card title="专业团队" :style="{ height: '133px' }">我们专注于手游研发，致力于打造一流的游戏精品。研发管理团队由具备10年以上经验的行业精锐组成，对游戏具有敏锐的市场洞察力。</t-card>
        </t-col>
        <t-col span="4">
          <t-card title="研发能力" :style="{ height: '133px' }">我们拥有10年以上的游戏研发经验，5年以上策略卡牌手游的制作经验。制作过月流水过亿、日活跃近200万的策略卡牌游戏。</t-card>
        </t-col>
        <t-col span="4">
          <t-card title="历史荣誉" :style="{ height: '133px' }">曾拿过谷歌3次全球推荐，苹果1次欧洲地区推荐、1次亚洲地区推荐。</t-card>
        </t-col>
      </t-row>
      <t-divider id="game"><t-tag theme="success" variant="light-outline">游戏产品</t-tag></t-divider>
      <t-row style="margin: 10px auto;" :gutter="12">
        <t-col span="3">
          <t-card title="萌猪出击">
            <video width="290" controls><source src="../assets/video/pigo.mp4" type="video/mp4"></video>
            <p>《萌猪出击》是一款消除类休闲小游戏，风格清新可爱。通过简单的点击操作，让所有小野猪根据其既定的路线成功逃跑即可获得游戏胜利。该产品兼备益智与解压的特点，深受玩家喜爱。</p>
          </t-card>
        </t-col>
        <t-col span="3">
          <t-card title="更多产品">敬请期待...</t-card>
        </t-col>
        <t-col span="3">
        </t-col>
        <t-col span="3">
        </t-col>
      </t-row>
      <t-divider id="contact"><t-tag theme="danger" variant="light-outline">联系我们</t-tag></t-divider>
      <t-descriptions>
        <t-descriptions-item label="公司名称">武汉市美故事科技有限公司</t-descriptions-item>
        <t-descriptions-item label="公司地址">湖北省武汉市东湖新技术开发区关东街道喻家湖东路12号金地华公馆1栋10层1007</t-descriptions-item>
        <t-descriptions-item label="商务合作">biz@meigushi.com</t-descriptions-item>
      </t-descriptions>
    </t-content>
    <t-footer :style="{ textAlign: 'center' }"> 
      <p>Copyright @ 2024 meigushi.com All Rights Reserved. 美故事科技 版权所有</p>
      <p><a href="https://beian.miit.gov.cn/" target="_blank"><t-link theme="primary" underline>鄂ICP备2024035828号-1</t-link></a></p>
      <p>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</p>
    </t-footer>
  </t-layout>
  <t-affix ref="affix" :offset-top="140" :offset-bottom="20">
    <t-back-top
      style="position: relative;"
      :visible-height="200"
      :offset="[0, 0]"
      shape="circle"
      theme="light"
      size="small"
    ></t-back-top>
  </t-affix>
</template>